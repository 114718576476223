<template>
  <div class="package-detail-container">
    <div class="top">
      <Header :title="'课程包'" :bgColor="'#00000000'" :leftArrowType="1" :titleColor="'#ffffff'" :leftClick="goBack">
      </Header>
      <div style="
          display: flex;
          width: 100%;
          padding: 0px 15px;
          box-sizing: border-box;
        ">
        <div>
          <div v-if="checkCollectionCover() == 1">
            <img :src="collectionInfo.album_contents[0].cover_pic_url" class="fistImg" />
          </div>
          <div v-else class="collectionImg">
            <img v-for="i in 4" :key="i" :src="collectionInfo.album_contents[i - 1].cover_pic_url" />
          </div>
        </div>
        <div style="
            width: 100%;
            flex: 1;
            padding-left: 15px;
            box-sizing: border-box;
          ">
          <p style="color: white; font-size: 18px">
            {{ collectionInfo.collection_name }}
          </p>
          <p style="
              color: white;
              font-size: 14px;
              line-height: 18px;
              margin-top: 10px;
            ">
            {{ collectionInfo.collection_intro }}
          </p>

          <div style="margin-top: 10px; display: flex; flex-wrap: wrap">
            <p class="tag-style">
              共{{ collectionInfo.album_contents.length }}个专辑
            </p>
            <p class="tag-style">共{{ totalAudioCounts }}个音频</p>
            <p class="tag-style">预估{{ totalKeshi }}个课时</p>
            <p class="tag-style">预计学习{{ totalWeeks }}周</p>
          </div>
        </div>
      </div>
      <div style="height: 1px; background-color: white; margin: 10px 15px"></div>
      <div style="
          color: white;
          font-size: 16px;
          width: 100%;
          padding: 0px 15px;
          box-sizing: border-box;
          display: flex;
          flex-wrap: wrap;
        ">
        分类：
        <div v-for="(tag, index) in collectionInfo.collection_labels" :key="index">
          <p v-if="tag && tag != ''" class="tag1-style"> {{ tag }}</p>
        </div>
      </div>
    </div>
    <div class="package-content">
      <div style="flex: 1;overflow-y: auto;">
        <div style="display: flex; width: 100%; align-items: center">
          <div style="flex: 1; text-align: center; cursor: pointer" @click="currentTab = 0">
            <img v-if="currentTab == 0" src="../../assets/app/iv_album_tab_kecheng_selected.png"
              style="width: 72px; height: auto; object-fit: cover" />
            <img v-else src="../../assets/app/iv_album_tab_kecheng_unselected.png"
              style="width: 72px; height: auto; object-fit: cover" />
          </div>
          <div style="flex: 1; text-align: center; cursor: pointer" @click="currentTab = 1">
            <img v-if="currentTab == 1" src="../../assets/app/iv_album_tab_content_selected.png"
              style="width: 64px; height: auto; object-fit: cover" />
            <img v-else src="../../assets/app/iv_album_tab_content_unselected.png"
              style="width: 64px; height: auto; object-fit: cover" />
          </div>
          <div style="flex: 1; text-align: center; cursor: pointer" @click="currentTab = 2">
            <img v-if="currentTab == 2" src="../../assets/app/iv_album_tab_parent_selected.png"
              style="width: 64px; height: auto; object-fit: cover" />
            <img v-else src="../../assets/app/iv_album_tab_parent_unselected.png"
              style="width: 64px; height: auto; object-fit: cover" />
          </div>
        </div>

        <div v-if="currentTab == 0" style="margin-top: 15px">
          <div v-for="(content, index) in collectionInfo.simple_intro_paragraphs" :key="index" style="margin-bottom: 5px">
            <p style="
                color: #454651;
                font-size: 14px;
                line-height: 22px;
                text-indent: 2rem;
              ">
              {{ content }}
            </p>
          </div>
        </div>
        <div v-if="currentTab == 1" style="margin-top: 15px">

          <div style="text-align: center;margin-bottom: 15px;">
            <img src="../../assets/app/add_audio_hint_package.png" style="width: 80%;height: auto;object-fit: cover;">
          </div>

          <div v-for="(content, index) in collectionInfo.album_contents" :key="index" class="album-item"
            @click="gotoAlbumDetail(content)">
            <img :src="content.cover_pic_url" style="
                width: 64px;
                height: 64px;
                object-fit: cover;
                border-radius: 12px;
              " />
            <div style="
                width: 100%;
                padding-left: 15px;
                flex: 1;
                box-sizing: border-box;
              ">
              <p style="color: #333333; font-size: 16px">
                {{ content.album_name }}
              </p>
              <p style="color: #666666; font-size: 14px; margin-top: 8px">
                {{ content.album_name_sub }}
              </p>
              <div style="margin-top: 8px; display: flex; align-items: center">
                <el-progress :percentage="formatProgress(content)" :show-text="false"
                  style="flex: 1; padding-right: 10px; box-sizing: border-box"></el-progress>
                <img v-if="havePurchasePackage == false" src="../../assets/app/ic_locker.png"
                  style="width: 28px; height: 28px; object-fit: cover" />
                <p v-else style="color: #6f76c6; font-size: 12px">
                  {{
                    content.album_songs.length -
                    content.album_no_answer_count +
                    "/" +
                    content.album_songs.length
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="currentTab == 2" style="margin-top: 15px">
          <div style="display: flex; align-items: center">
            <img src="../../assets/app/songs2.png" style="width: 30px; height: auto" />
            <p style="
                color: #333333;
                font-size: 18px;
                margin-left: 10px;
                margin-top: 5px;
              ">
              课程特点
            </p>
          </div>
          <div style="margin-top: 10px">
            <div v-for="(content, index) in collectionInfo.highlights_paragraphs" :key="index">
              <p style="
                  color: #454651;
                  font-size: 14px;
                  text-indent: 2rem;
                  margin-bottom: 8px;
                  line-height: 22px;
                ">
                {{ content }}
              </p>
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-top: 10px">
            <img src="../../assets/app/songs3.png" style="width: 30px; height: auto" />
            <p style="
                color: #333333;
                font-size: 18px;
                margin-left: 10px;
                margin-top: 5px;
              ">
              学习收获
            </p>
          </div>
          <div style="margin-top: 10px">
            <div v-for="(content, index) in collectionInfo.values_paragraphs" :key="index">
              <p style="
                  color: #454651;
                  font-size: 14px;
                  text-indent: 2rem;
                  margin-bottom: 8px;
                  line-height: 22px;
                ">
                {{ content }}
              </p>
            </div>
          </div>
          <div style="display: flex; align-items: center; margin-top: 10px">
            <img src="../../assets/app/songs4.png" style="width: 30px; height: auto" />
            <p style="
                color: #333333;
                font-size: 18px;
                margin-left: 10px;
                margin-top: 5px;
              ">
              适合孩子
            </p>
          </div>
          <div style="margin-top: 10px">
            <div v-for="(content, index) in collectionInfo.targets_paragraphs" :key="index">
              <p style="
                  color: #454651;
                  font-size: 14px;
                  text-indent: 2rem;
                  margin-bottom: 8px;
                  line-height: 22px;
                ">
                {{ content }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 100%;padding-top: 12px;box-sizing: border-box;">
        <p v-if="havePurchasePackage" class="btn-purchased">已购买</p>
        <div v-else-if="groupPruchaseInfo == null" style="position: relative;">
          <div style="display: flex;width: 100%;">
            <div class="purchase-container" @click="checkMustRead()">
              <div class="purchase-hint">
                <p style=";text-decoration: line-through;">原价：{{ collectionInfo.collection_price_show }} </p>
                <p v-if="showReductionPrice" style="margin-left: 8px;">折扣价：{{ normalPayPoints
                }}</p>
              </div>
              <p class="btn-purchase">立即解锁</p>
            </div>
            <div style="width: 20px;"></div>
            <div class="purchase-container" @click="checkGroupPurchase()">
              <div class="purchase-hint">
                <p style=";text-decoration: line-through;">原价：{{ collectionInfo.collection_price_show }}</p>
                <p style="margin-left: 8px;">{{ '拼团价：' + groupPayPoints }}
                </p>
              </div>
              <p class="btn-purchase">拼团购买</p>
            </div>
          </div>
          <p @click="showAuditionDialog = true"
            v-if="havePurchasePackage == false && hasApplyAudition == false && collectionInfo.open_audition && collectionInfo.open_audition == 'YES' && collectionInfo.audition_number && collectionInfo.audition_number > 0"
            class="btn-audition">免费试课，先听后买</p>
        </div>
        <div v-else class="btn-groupPurchase" @click="clickShare()">{{ groupPurchaseCountDownText }}</div>
      </div>
    </div>

    <el-dialog :visible.sync="showPurchasePackageDialog" title="购买课程包" width="95%">
      <p style="line-height: 22px;">{{
        '购买课程包需要消耗' + normalPayPoints + '可能点，还剩下' + (userPoints -
          normalPayPoints) + '可能点，您确定购买吗？'
      }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showPurchasePackageDialog = false">取 消</el-button>
        <el-button type="primary" @click="purchasePackage">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="showPointsNotEnoughDialog" :title="pointsNotEnoughTitle" width="85%">
      <p style="line-height: 22px;">{{ pointsNotEnoughContent }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showPointsNotEnoughDialog = false">取消</el-button>
        <el-button type="primary" @click="gotoPurchasePoints">购买可能点</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="showPurchaseSuccessDialog" title="提示" width="95%">
      <p style="line-height: 22px;">{{ purchaseSuccessHint }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showPurchaseSuccessDialog = false">待会设置</el-button>
        <el-button type="primary" @click="gotoScheduleTab">现在设置</el-button>
      </span>
    </el-dialog>
    <el-dialog :visible.sync="showReadDialog" :title="mustReadInfo.name" width="85%" :show-close="false">
      <div>
        <div v-for="(content, index) in mustReadInfo.contents" :key="index"
          style="color: #333333;font-size: 13px;line-height: 20px">
          <p>{{ content }}</p>
          <br>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmMustRead">确认</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="showAuditionDialog" title="免费试课程" width="95%">
      <p style="line-height: 22px;">{{ '像正常购买一样将音频排入课表，先免费试课，满意再购买。' }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAuditionDialog = false">取消</el-button>
        <el-button type="primary" @click="doApplyAudition">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="showGroupPurchaseDialog" title="拼团购买" width="85%">
      <p style="line-height: 22px;color: #333;">{{ '邀请朋友一起购买。拼成后课程包自动解锁，拼团失败可能点将退还。' }}</p>
      <p style="line-height: 22px;color: #333;margin-top: 10px;">消耗可能点：{{ groupPayPoints }}
      </p>
      <p style="line-height: 22px;color: #333;">买后剩余可能点：{{ userPoints - groupPayPoints }}
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showGroupPurchaseDialog = false">取 消</el-button>
        <el-button type="primary" @click="doGroupPurchase">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="showGroupPurchaseSuccessDialog" title="拼团购买" width="85%">
      <p style="line-height: 22px;color: #333;">{{ '拼团成功，请将此拼团分享给朋友，还差1人即可拼成。' }}</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showGroupPurchaseSuccessDialog = false">取消</el-button>
        <el-button type="primary" @click="clickShare">分享给朋友</el-button>
      </span>
    </el-dialog>

    <el-drawer title="分享到" :visible.sync="showShareDialog" direction="btt">
      <div style="display: flex;">
        <div style="flex: 1;text-align: center;cursor: pointer;" @click="gotoShare(1)">
          <img src="../../assets/app/weixin.png" style="width: 36px;height: 36px;object-fit: cover;">
          <p style="margin-top: 10px;">微信好友</p>
        </div>
        <div style="flex: 1;text-align: center;cursor: pointer;" @click="gotoShare(2)">
          <img src="../../assets/app/pengyouquan.png" style="width: 36px;height: 36pxobject-fit: cover;;">
          <p style="margin-top: 10px;">朋友圈</p>
        </div>
        <div style="flex: 1;text-align: center;cursor: pointer;" @click="gotoShare(3)" class="share-copy-link"
          :data-clipboard-text="shareUrl">
          <img src="../../assets/app/link.png" style="width: 36px;height: 36px;object-fit: cover;">
          <p style="margin-top: 10px;">复制链接</p>
        </div>
      </div>
    </el-drawer>

  </div>
</template>
<script>
import Header from "../../components/app/Header.vue";
import { checkUserHasApplyCollection, getCollection, getUserByUserID, addPurchase, finishGroupPurchase, checkGroupPurchase, getMyPurchases, getKechengMustRead, getDefaultKid, addGroupPurchase } from "../../api/app";
import { loginById, getSignPackageFWH } from "../../api/keneng";
import {
  setAppUserId,
  setOpenId,
  setAppLoginToken,
  setAppKidId,
  getAppUserId, setHomeFragmentScheduleTabIndex, setHomeTabIndex
} from "../../utils/store";
import Clipboard from 'clipboard'
import { Loading } from "element-ui";

export default {
  name: "index",
  components: {
    Header,
  },
  data() {

    return {
      user_id: getAppUserId(),
      package_id: this.$route.query.id,
      collectionInfo: {},
      totalAudioCounts: 0,
      totalKeshi: 0,
      totalWeeks: 0,
      currentTab: 0,
      userPoints: 0,
      havePurchasePackage: false,
      showReductionPrice: false,
      reductionPriceVaule: 0,
      showPurchasePackageDialog: false,
      showPointsNotEnoughDialog: false,
      showPurchaseSuccessDialog: false,
      showAuditionDialog: false,
      purchaseSuccessHint: '',
      showScheduleTabIndex: 0,
      mustReadInfo: '',
      showReadDialog: false,
      purchaseCount: 0,
      showGroupPurchaseDialog: false,
      pointsNotEnoughTitle: '',
      pointsNotEnoughContent: '',
      showGroupPurchaseSuccessDialog: false,
      showShareDialog: false,

      groupPruchaseInfo: undefined,
      startGroupPurchaseCountDownTimer: undefined,
      groupPurchaseCountDownText: '',
      shareUrl: '',

      groupPayPoints: 0.0,
      normalPayPoints: 0.0,

      hasApplyAudition: false
    };
  },
  async mounted() {

    getKechengMustRead().then((res) => {
      if (res.data.code == 0) {
        this.mustReadInfo = res.data.data
      }
    })

    //check是否登录
    if (!this.user_id || this.user_id == undefined || this.user_id == '') {
      //检查是否从公众号点击进来的
      if (this.$route.query.user_id) {
        this.user_id = this.$route.query.user_id
        this.initUserInfo()
      } else {
        this.$router.replace('/app-index')
      }
      return
    }
    this.fetchData()
    this.requestUserpoints()
    this.checkIsInGroupPurchase()

  },
  methods: {

    gotoShare(type) {
      if (type == 1 || type == 2) {
        this.doShareFriends()
        return
      }
      if (type == 3) {
        const clipboard = new Clipboard('.share-copy-link')
        clipboard.on('success', () => {
          this.$message.success('已复制链接地址')
          clipboard.destroy()
        })
        clipboard.on('error', () => {
          this.$message.error('Copy failed')
          clipboard.destroy()
        })
      }
      this.showShareDialog = false
    },
    doShareFriends() {
      //https://developers.weixin.qq.com/community/develop/doc/0004aa00fe885053968d7129356800
      let that = this
      let url = window.location.href
      let collection_name = this.collectionInfo.collection_name
      let link = this.shareUrl
      let imgUrl = this.collectionInfo.cover_pic_url ? this.collectionInfo.cover_pic_url : ''
      getSignPackageFWH(url).then((res) => {
        const result = res.data
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.appId, // 必填，公众号的唯一标识
          timestamp: result.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.nonceStr, // 必填，生成签名的随机串
          signature: result.signature, // 必填，签名
          jsApiList: [
            'updateAppMessageShareData',
            'updateTimelineShareData'
          ]
        })
        setTimeout(() => {
          wx.ready(() => {
            wx.updateAppMessageShareData({
              title: collection_name,
              desc: '这个课程不错，我在拼，还差1个人就拼成了',
              link: link,
              imgUrl: imgUrl,
              success: (res) => {
              },
              fail: function (error) {
                console.log('updateAppMessageShareData error:', error);
              }
            })
            wx.updateTimelineShareData({
              title: collection_name,
              desc: '这个课程不错，我在拼，还差1个人就拼成了',
              link: link,
              imgUrl: imgUrl,
              success: (res) => {
              },
              fail: function (error) {
                console.log('updateTimelineShareData error:', error);
              }
            })
            that.$message.success('请点击右上角按钮，选择好友或者朋友圈进行分享')
            that.showShareDialog = false
          })
          wx.error((err) => {
            console.log(err)
          })
        }, 500)
      })
    },

    checkIsInGroupPurchase() {
      checkGroupPurchase(getAppUserId(), this.package_id).then((res) => {
        this.groupPruchaseInfo = res.data.data
        if (this.groupPruchaseInfo != null) {
          this.doStartGroupPurchaseCountDown()
        }
      })
    },
    doStartGroupPurchaseCountDown() {
      let that = this
      let currentTime = new Date().valueOf() / 1000
      let totalSeconds = parseInt(this.groupPruchaseInfo.deadline - currentTime)
      this.startGroupPurchaseCountDownTimer = setInterval(() => {
        totalSeconds--
        if (totalSeconds < 0) {
          clearInterval(that.startGroupPurchaseCountDownTimer)
          that.$message.warning('拼团结束')
          that.checkIsInGroupPurchase()
          that.notifyFinishGroupPurchase()
        }
        that.groupPurchaseCountDownText = '拼团中 还剩：' + that.secondToTime(totalSeconds)
      }, 1 * 1000)
    },
    notifyFinishGroupPurchase() {
      finishGroupPurchase(this.groupPruchaseInfo._id.$id).then((res) => {

      })
    },
    secondToTime(s) {
      var t = '';
      var hour = Math.floor(s / 3600)
      var min = Math.floor(s / 60) % 60
      var sec = s % 60
      if (hour < 10) {
        t = '0' + hour + ":"
      } else {
        t = hour + ":"
      }
      if (min < 10) {
        t += "0"
      }
      t += min + ":"
      if (sec < 10) {
        t += "0"
      }
      t += parseInt(sec)
      return t
    },

    clickShare() {
      let host = window.location.host
      this.shareUrl = "https://" + host + '/app-groupPurchase?id=' + this.groupPruchaseInfo._id.$id
      this.showGroupPurchaseSuccessDialog = false
      this.showShareDialog = true
    },

    checkGroupPurchase() {
      let price = this.groupPayPoints
      if (this.userPoints > price) {
        this.showGroupPurchaseDialog = true
      } else {
        this.pointsNotEnoughTitle = '拼团购买'
        this.pointsNotEnoughContent = '拼团购买消耗' + price + '可能点，当前可能点余额不足，请先购买可能点。'
        this.showPointsNotEnoughDialog = true
      }
    },

    doGroupPurchase() {
      let user_id = getAppUserId()
      let content_id = this.package_id
      let content_type = 'collection'
      let price = this.groupPayPoints
      addGroupPurchase(user_id, content_id, content_type, price).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.groupPruchaseInfo = result.data
          this.$message.success('拼团成功')
          this.showGroupPurchaseSuccessDialog = true
          this.requestUserpoints()
          this.checkIsInGroupPurchase()
        } else {
          this.$message.error(result.msg)
        }
        this.showGroupPurchaseDialog = false
      })
    },

    async initUserInfo() {
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      //调用获取用户信息接口
      let userResult = await loginById(this.user_id)
      let success = true;
      if (userResult.data) {
        if (userResult.data.code == 0) {
          let user_data = userResult.data.data
          setAppUserId(this.user_id)
          setOpenId(user_data.open_id)
          setAppLoginToken(user_data.login_token)
          let kidIdResult = await getDefaultKid(this.user_id)
          let kid_id = kidIdResult.data.data._id.$id
          setAppKidId(kid_id)
        } else {
          success = false
        }
      } else {
        success = false
      }
      loadingInstance.close()
      if (success == false) {
        this.$router.replace('/app-index')
        return
      }
      this.fetchData();
      this.requestUserpoints()
      this.checkIsInGroupPurchase()
    },

    doApplyAudition() {
      let price_info = {
        total_album_price: this.collectionInfo.total_album_price ? this.collectionInfo.total_album_price : '',
        total_purchased_price: this.collectionInfo.total_purchased_price ? this.collectionInfo.total_purchased_price : '',
        total_unpurchase_price: this.collectionInfo.total_unpurchase_price ? this.collectionInfo.total_unpurchase_price : '',
      }
      let params = {
        function: 'addPurchase',
        user_id: getAppUserId(),
        purchase_type: 'collection',
        content_id: this.package_id,
        apply_type: 'audition',
        price: this.collectionInfo.actual_collection_price,
        price_info: price_info
      }
      addPurchase(params).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.hasApplyAudition = true
          this.showAuditionDialog = false
          this.$message.success('申请试课成功')
          this.formatPayPoints()
          this.doShowPurchaseDialog('audition')
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    confirmMustRead() {
      this.showReadDialog = false
      this.showPurchasePackageDialog = true
    },
    checkMustRead() {
      let price = this.normalPayPoints
      if (this.userPoints < price) {
        this.pointsNotEnoughTitle = '购买课程包'
        this.pointsNotEnoughContent = '可能点不足'
        this.showPointsNotEnoughDialog = true
        return
      }
      let current_time = new Date().valueOf() / 1000
      getMyPurchases(getAppUserId(), current_time).then((res) => {
        this.purchaseCount = res.data.code == 0 ? res.data.data.length : 0
        if (this.purchaseCount <= 0) {
          this.showReadDialog = true
        } else {
          this.showPurchasePackageDialog = true
        }
      })
    },

    async doShowPurchaseDialog(type) {
      if (type == 'audition') {
        this.purchaseSuccessHint = '你已开启此课程的免费试课，请前往“智能排课-内容安排”设置每次收听的音频数量。'
        this.showScheduleTabIndex = 2
        this.showPurchaseSuccessDialog = true
        return
      }
      let current_time = new Date().valueOf() / 1000
      let reuslt = await getMyPurchases(getAppUserId(), current_time)
      let orderSize = reuslt.data.code == 0 ? reuslt.data.data.length : 0
      if (orderSize <= 1) {
        this.purchaseSuccessHint = '你已经成功购买第一个课程，请前往“智能排课-时间安排”设置小朋友的收听时间。'
        this.showScheduleTabIndex = 1
      } else {
        this.purchaseSuccessHint = '你已经成功购买此课程，（共' + this.totalAudioCounts + '个音频），请前往“智能排课-内容安排”设置每次收听的音频数量吧。'
        this.showScheduleTabIndex = 2
      }
      this.showPurchaseSuccessDialog = true
    },

    gotoScheduleTab() {
      setHomeTabIndex(0)
      setHomeFragmentScheduleTabIndex(this.showScheduleTabIndex)
      this.$router.replace({
        path: '/app-home'
      })
    },
    gotoPurchasePoints() {
      this.$router.push({
        path: '/app-products'
      })
      this.showPointsNotEnoughDialog = false
    },

    purchasePackage() {
      this.showPurchasePackageDialog = false
      let price_info = {
        total_album_price: this.collectionInfo.total_album_price ? this.collectionInfo.total_album_price : '',
        total_purchased_price: this.collectionInfo.total_purchased_price ? this.collectionInfo.total_purchased_price : '',
        total_unpurchase_price: this.collectionInfo.total_unpurchase_price ? this.collectionInfo.total_unpurchase_price : '',
      }
      let params = {
        function: 'addPurchase',
        user_id: getAppUserId(),
        purchase_type: 'collection',
        apply_type: 'purchase',
        content_id: this.package_id,
        price: this.normalPayPoints,
        price_info: price_info
      }
      addPurchase(params).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.$message.success('恭喜你解锁' + this.collectionInfo.collection_name)
          this.fetchData()
          this.requestUserpoints()
          this.doShowPurchaseDialog('purchase')
        } else if (result.code == -1) {
          this.pointsNotEnoughTitle = '购买课程包'
          this.pointsNotEnoughContent = '可能点不足'
          this.showPointsNotEnoughDialog = true
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    gotoAlbumDetail(album) {
      this.$router.push({
        path: '/app-album',
        query: {
          id: album._id.$id
        }
      })
    },

    requestUserpoints() {
      getUserByUserID(getAppUserId()).then((res) => {
        if (res.data.code == 0) {
          this.userPoints = res.data.data.keneng_points;
        }
      });
    },
    formartReductionPrice() {
      let collection_price_show = 0
      if (this.collectionInfo.collection_price_show) {
        collection_price_show = this.collectionInfo.collection_price_show
      }
      if (collection_price_show > 0) {
        this.showReductionPrice = true
        this.reductionPriceVaule = '-' + parseInt(((collection_price_show - this.collectionInfo.actual_collection_price) / collection_price_show) * 100) + '%'
      }
    },

    formatProgress(content) {
      let album_no_answer_count = content.album_no_answer_count;
      let album_songs = content.album_songs.length;
      let answer_count = album_songs - album_no_answer_count;
      let progress = parseInt((answer_count / album_songs) * 100);
      return progress;
    },

    fetchData() {
      let loadingInstance = Loading.service({
        fullscreen: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      });
      getCollection(this.package_id).then((res) => {
        this.collectionInfo = res.data.data;
        this.collectionInfo.album_contents.forEach((album) => {
          this.totalAudioCounts =
            this.totalAudioCounts + album.album_songs.length;
        });
        this.totalKeshi = parseInt(Math.ceil(this.totalAudioCounts / 5));
        this.totalWeeks = parseInt(Math.ceil(this.totalAudioCounts / (5 * 2)));

        let collection_purchase_available =
          this.collectionInfo.collection_purchase_available;

        if (collection_purchase_available == "YES") {
          let collection_price = this.collectionInfo.actual_collection_price;
          let purchase_status = this.collectionInfo.purchase_status;
          if (collection_price > 0 && purchase_status == false) {
            this.havePurchasePackage = false;
          } else {
            this.havePurchasePackage = true;
          }
        } else {
          this.havePurchasePackage = true;
        }
        this.formartReductionPrice()
        loadingInstance.close()

        this.checkUserHasApply()
      });
    },

    checkCollectionCover() {
      let albumContents = this.collectionInfo.album_contents;
      if (albumContents && albumContents.length < 4) {
        return 1;
      }
      let enbale = 0;
      for (let i = 0; i < 4; i++) {
        let album = albumContents[i];
        if (!album.cover_pic_url) {
          enbale = 1;
          break;
        }
        return enbale;
      }
    },

    checkUserHasApply() {
      checkUserHasApplyCollection(getAppUserId(), this.package_id, 'collection').then((res) => {
        this.formatPayPoints(res.data.data != null)
        this.hasApplyAudition = (res.data.data != null)
      })
    },

    formatPayPoints(add) {
      if (add) {
        this.groupPayPoints = this.collectionInfo.actual_collection_price * 1.25 * 0.75
        this.groupPayPoints = this.groupPayPoints.toFixed(2)

        this.normalPayPoints = this.collectionInfo.actual_collection_price * 1.25
        this.normalPayPoints = this.normalPayPoints.toFixed(2)
      } else {

        this.groupPayPoints = this.collectionInfo.actual_collection_price * 0.75
        this.groupPayPoints = this.groupPayPoints.toFixed(2)

        this.normalPayPoints = this.collectionInfo.actual_collection_price * 1
        this.normalPayPoints = this.normalPayPoints.toFixed(2)
      }
    },

    goBack() {
      this.$router.replace('/app-home')
    },
  },
  beforeDestroy() {
    if (this.startGroupPurchaseCountDownTimer) {
      clearInterval(this.startGroupPurchaseCountDownTimer)
    }
  }
};
</script>

<style lang="less" scoped>
.share-copy-link {}

.btn-groupPurchase {
  cursor: pointer;
  height: 42px;
  line-height: 42px;
  text-align: center;
  color: white;
  box-sizing: border-box;
  background-color: #ff5e45;
  border-radius: 24px;
  font-size: 16px;
}

.btn-audition {
  text-align: center;
  color: #333333;
  font-size: 14px;
  margin: 12px 0px;
  text-decoration: underline;
  cursor: pointer;
}

.btn-purchased {
  width: 100%;
  height: 42px;
  line-height: 42px;
  text-align: center;
  color: #686CC0;
  background-color: #D1CFF6;
  border-radius: 24px;
  font-size: 16px;
}

.purchase-container {
  cursor: pointer;
  width: 100%;
  flex: 1;
  border-radius: 6px;
  background-color: orange;
  font-size: 14px;
}

.purchase-hint {
  display: flex;
  padding: 7px 0px;
  justify-content: center;
  text-align: center;
  color: white;
  border-radius: 6px;
}

.btn-purchase {
  text-align: center;
  padding: 10px 0px;
  color: white;
  box-sizing: border-box;
  background-color: #5753b0;
  border-radius: 6px;
}

.album-item {
  cursor: pointer;
  display: flex;
  margin-bottom: 25px;
}

.tag1-style {
  color: white;
  font-size: 12px;
  padding: 4px 8px;
  box-sizing: border-box;
  margin-right: 10px;
  margin-bottom: 8px;
  background-color: rgba(0, 0, 0, 0.38);
  border-radius: 14px;
}

.tag-style {
  color: white;
  font-size: 12px;
  padding: 4px 8px;
  box-sizing: border-box;
  margin-right: 10px;
  margin-bottom: 8px;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 5px;
}

.package-detail-container {
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: white;

  .top {
    position: relative;
    width: 100%;
    height: 400px;
    background: linear-gradient(135deg, #727bc9 0%, #5753b0 100%);
  }

  .package-content {
    position: absolute;
    margin-top: 280px;
    width: 100%;
    height: calc(100vh - 280px);
    background: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 15px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }
}

.fistImg {
  width: 110px;
  height: 110px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 12px;
}

.collectionImg {
  display: flex;
  flex-wrap: wrap;
  width: 110px;
  height: 110px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 12px;
  box-sizing: border-box;
  flex-shrink: 0;

  img {
    width: 50%;
    height: 50%;
  }

  img:nth-child(1) {
    border-radius: 12px 0 0 0;
  }

  img:nth-child(2) {
    border-radius: 0 12px 0 0;
  }

  img:nth-child(3) {
    border-radius: 0 0 0 12px;
  }

  img:nth-child(4) {
    border-radius: 0 0 12px 0;
  }
}
</style>
